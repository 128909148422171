import React from "react";
import {
    ImageContainer,
    OfferSectionItem,
    OfferTextContainer,
    OfferSectionImage,
} from "./MainOffersPresentationSectionWithImages.style";
import {
    Description,
    MainOffersPresentationSectionWrapper,
    MainOffersPresentationTitle,
    OfferSectionTitle,
} from "../CommonStyles/CommonStyles.style";
import { BackgroundBlur } from "../PageHeader/PageHeader.style";

const MainOffersPresentationSectionWithImage = ({
    offers,
    title,
    description,
}) =>
    offers && (
        <MainOffersPresentationSectionWrapper>
            <MainOffersPresentationTitle>{title}</MainOffersPresentationTitle>
            <Description>{description}</Description>
            {offers.map(offer => (
                <OfferSectionItem>
                    <ImageContainer>
                        <OfferSectionImage imgSrc={offer.imgSrc} />
                        <BackgroundBlur />
                    </ImageContainer>
                    <OfferTextContainer>
                        <OfferSectionTitle>{offer.title}</OfferSectionTitle>
                        <Description>{offer.text}</Description>
                    </OfferTextContainer>
                </OfferSectionItem>
            ))}
        </MainOffersPresentationSectionWrapper>
    );

export default MainOffersPresentationSectionWithImage;
