import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const OfferSectionItem = styled.div`
    margin-top: 7.5rem;
    align-items: center;
    display: flex;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        flex-direction: column;
        align-items: center;
        margin-top: 5rem;
    }
    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin-top: 7.1875rem;
    }
`;

export const SquareSectionContainer = styled.div`
    display: flex;
    margin: 3rem -16rem 0;
`;

export const OfferSectionImage = styled.div`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    height: 21.5rem;
    width: 21.5rem;
    overflow: hidden;
    background: url(${props => props.imgSrc});
    background-size: cover;
    -webkit-background-size: cover;
    background-position: center center;
    z-index: -10;
`;

export const ImageContainer = styled.div`
    position: relative;
    margin-right: 5rem;
    border: 0.01rem solid #c4b180;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin-bottom: 6.875rem;
        margin-right: 0;
    }
    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin-bottom: 4.6rem;
    }
`;
export const OfferTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
